import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Layout, RandomOp, Typography, Separator,
} from '../../components';
import {
  MainTitle, MainPart, Figure, FigureDouble, FigureTriple, List,
} from '../../components/opComponents';

const InstabiliteAnterieureDeLepaule = () => (
  <Layout
    title="Instabilité antérieure de l'épaule : Diagnostic et traitement"
    description="Le diagnostic d'instabilité antérieure d'épaule est fait par la présence d'une appréhension (sensation de déboitement) de l'épaule à l'armée du bras."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-lepaule" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de l&apos;épaule</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Instabilité antérieure de l'épaule" />
        <MainPart title="Qu'est-ce qu'une instabilité antérieure de l'épaule ?">
          <Typography>
            L&apos;instabilité antérieure représente 95% des cas d&apos;instabilité
            de l&apos;épaule. Trois cas de figures peuvent être observés :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Les luxations récidivantes
                <Typography variant="span">
                  {' '}
                  : la tête de l&apos;humérus se déboite et sort complètement de la
                  cavité de la glène. La luxation nécessite le plus souvent
                  l&apos;intervention d&apos;une tierce personne afin de la réduire.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Les subluxations récidivantes
                <Typography variant="span">
                  {' '}
                  : la tête de l&apos;humérus sort partiellement de la cavité
                  de la glène. Les subluxations sont habituellement
                  réduites par le patient lui-même.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                L&apos;épaule douloureuse instable
                <Typography variant="span">
                  {' '}
                  : le patient ne décrit pas de phénomènes de luxation ou
                  de subluxation mais présente une douleur et/ou
                  appréhension de l&apos;épaule à l&apos;armée du bras.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
        <MainPart title="Comment est articulée l'épaule ?">
          <Typography>
            L&apos;articulation de l&apos;épaule est naturellement instable et peut être assimilée
            à une balle de golf posée sur une cheville : la tête ronde de l&apos;humérus
            s&apos;articule avec la petite surface plate de la glène (Figure 1).
          </Typography>
          <Figure maxWidth="max-w-[400px]" imageName="figure1-instabiliteanterieuredelepaule.jpg" caption="Figure 1. L&apos;articulation gléno-humérale est une articulation naturellement instable telle une balle de golf posée sur une cheville" currentOpTitle="Instabilité antérieure de l'épaule" />
          <Typography>
            Des éléments stabilisateurs sont donc nécessaires
            afin d&apos;éviter toute instabilité de l&apos;épaule (Figure 2) :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Les stabilisateurs statiques
                <Typography variant="span">
                  {' '}
                  : la capsule articulaire, le bourrelet glénoïdien et les
                  ligaments gléno-huméraux (supérieur, moyen et surtout inférieur)
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Les stabilisateurs dynamiques
                <Typography variant="span">
                  {' '}
                  :  les muscles de la coiffe des rotateurs et le muscle deltoïde
                </Typography>
              </Typography>
            </List.Item>
          </List>
          <FigureDouble
            maxWidth="max-w-[500px]"
            height="h-[300px]"
            imageNameOne="figure2-instabiliteanterieuredelepaule.jpg"
            imageNameTwo="figure2bis-instabiliteanterieuredelepaule.jpg"
            caption="Figure 2. La coiffe des rotateurs et le muscle Deltoïde"
            currentOpTitle="Instabilité antérieure de l'épaule"
          />
        </MainPart>
        <MainPart title="Quelles sont les causes d'une instabilité antérieure de l'épaule ?">
          <Typography>
            Le premier épisode de luxation d&apos;épaule survient souvent suite
            à un traumatisme violant (Figure 3).
            Il peut s&apos;agir d&apos;un choc direct sur l&apos;épaule
            ou d&apos;un traumatisme en position de l&apos;armée du
            bras. Les récidives de luxation sont le plus souvent occasionnées par des traumatismes
            moins importants.
          </Typography>
          <FigureDouble
            maxWidth="max-w-[500px]"
            height="h-[300px]"
            imageNameOne="figure3-instabiliteanterieuredelepaule.jpg"
            imageNameTwo="figure3bis-instabiliteanterieuredelepaule.jpg"
            caption="Figure 3. Luxation antérieure de l'épaule"
            currentOpTitle="Instabilité antérieure de l'épaule"
          />
          <Typography>
            Chaque épisode d&apos;instabilité est ainsi responsable de lésions dégénératives
            de l&apos;épaule : lésion du bourrelet glénoïdien, des ligaments gléno-huméraux
            (surtout inférieur) et ostéo-cartilagineuses (encoche de la tête humérale
            et fracture de la glène).
          </Typography>
        </MainPart>
        <MainPart title="Comment est diagnostiqué une instabilité de l'épaule ?">
          <Typography>
            Le diagnostic d&apos;instabilité antérieure d&apos;épaule
            est fait par la présence d&apos;une
            appréhension (sensation de déboitement) de l&apos;épaule
            à l&apos;armée du bras (Figure 4).
          </Typography>
          <Typography>
            Cette appréhension doit disparaitre avec la mise en place d&apos;une pression
            antéro-postérieure sur la tête de l&apos;humérus (test de
            recentrage ou « relocation test »)
            (Figure 5).
          </Typography>
          <Figure maxWidth="max-w-[400px]" imageName="figure4-instabiliteanterieuredelepaule.jpg" caption="Figure 4. Test d&apos;appréhension ou position de l&apos;armée du bras permettant d&apos;objectiver une instabilité antérieure de l&apos;épaule" currentOpTitle="Instabilité antérieure de l'épaule" />
          <Figure imageName="figure5-instabiliteanterieuredelepaule.jpg" caption="Figure 5. Test de recentrage (“Relocation test”) permettant de masquer l&apos;appréhension" currentOpTitle="Instabilité antérieure de l'épaule" />
          <Typography>
            Il est important de rechercher des signes
            d&apos;hyperlaxité lors de l&apos;examen clinique :
          </Typography>
          <List>
            <List.Item>
              <Typography weight="bold">
                Hyperlaxité antérieure
                <Typography variant="span"> : présence d&apos;un tiroir antérieur et / ou d&apos;une rotation externe supérieure à 90° (Figure 6).</Typography>
              </Typography>
            </List.Item>
          </List>
          <Figure imageName="figure6-instabiliteanterieuredelepaule.jpg" caption="Figure 6. Tiroir gléno-huméral témoignant d&apos;une hyperlaxité antéro-postérieure de l&apos;épaule" currentOpTitle="Instabilité antérieure de l'épaule" />
          <List>
            <List.Item>
              <Typography weight="bold">
                Hyperlaxité inférieure
                <Typography variant="span"> : présence du signe du Sulcus (Figure 7).</Typography>
              </Typography>
            </List.Item>
          </List>
          <Figure imageName="figure7-instabiliteanterieuredelepaule.jpg" caption="Figure 7. Test du Sulcus témoignant d&apos;une hyperlaxité inférieure de l&apos;épaule" currentOpTitle="Instabilité antérieure de l'épaule" />
          <List>
            <List.Item>
              <Typography weight="bold">
                Hyperlaxité généralisée
                <Typography variant="span"> : présence d&apos;un recurvatum du coude et/ou du genou, possibilité de toucher son avant-bras avec son pouce, entorses de cheville à répétition...</Typography>
              </Typography>
            </List.Item>
          </List>
          <Typography>
            Il est également important de rechercher une éventuelle instabilité postérieure
            associée (instabilité multi-directionnelle) et d&apos;éliminer toute luxation
            volontaire, ne relevant pas de la chirurgie.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer une instabilité antérieure de l'épaule ?">
          <List>
            <List.Item>
              <Typography>
                Les radiographies d&apos;épaule de face
                (rotation neutre, interne et externe) et de profil
                (Lamy et Bernageau) permettent d&apos;objectiver les lésions osseuses de la
                tête humérale et de la glène (Figure 8).
              </Typography>
            </List.Item>
          </List>
          <Figure imageName="figure8-instabiliteanterieuredelepaule.jpg" caption="Figure 8. Radiographies de l&apos;épaule de face : encoche de la tête humérale associée à une fracture du bord antéro-inférieur de la glène, secondaires à une luxation antérieure de l&apos;épaule" currentOpTitle="Instabilité antérieure de l'épaule" />
          <List>
            <List.Item>
              <Typography>
                <a className="underline text-link hover:text-primary" href="https://www.acrim.fr/nos-examens/scanner/arthroscanner/epaule/" target="_blank" rel="noreferrer">L&apos;arthro-scanner</a>
                {' '}
                (scanner avec injection de produit de contraste dans l&apos;épaule)
                permet d&apos;apprécier de manière précise les tissus mous (coiffe des rotateurs,
                bourrelet glénoïdien), l&apos;os
                (encoche de la tête de l&apos;humérus, fracture de glène)
                et le cartilage (Figure 9).
              </Typography>
            </List.Item>
          </List>
          <Figure imageName="figure9-instabiliteanterieuredelepaule.jpg" caption="Figure 9. Arthro-scanner, coupe horizontale: encoche de la tête humérale, émoussement de la glène et désinsertion du bourrelet glénoidien antéro-inférieur, secondaires à une luxation antérieure de l&apos;épaule" currentOpTitle="Instabilité antérieure de l'épaule" />
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux en cas d'instabilité antérieure de l'épaule ?">
          <Typography size="mdx" weight="bold">Prise en charge médicale :</Typography>
          <Typography>
            La rééducation est principalement indiquée en cas de premier épisode de luxation,
            de subluxations ou en cas d&apos;épaule douloureuse instable. Elle a pour objectif
            de renforcer des stabilisateurs dynamiques de l&apos;épaule.
          </Typography>
          <Typography size="mdx" weight="bold">Prise en charge chirurgicale :</Typography>
          <Typography>
            Le traitement chirurgical est proposé dans les cas suivants :
          </Typography>
          <List>
            <List.Item>
              <Typography>1er épisode de luxation chez les sportifs de haut niveau</Typography>
            </List.Item>
            <List.Item>
              <Typography>Dès le deuxième épisode de luxation</Typography>
            </List.Item>
            <List.Item>
              <Typography>Subluxations malgré la rééducation</Typography>
            </List.Item>
            <List.Item>
              <Typography>Épaule douloureuse instable malgré la rééducation</Typography>
            </List.Item>
          </List>
          <Typography>
            Le but étant de stabiliser l&apos;épaule afin de limiter
            la survenue de lésions dégénératives
            (labrales, osseuses et cartilagineuses) et de permettre ainsi un usage de l&apos;épaule
            sans appréhension et la reprise du sport.
          </Typography>
          <Typography>
            Deux principales interventions de stabilisation de l&apos;épaule ont été décrites :
            la butée de Latarjet et l&apos;opération de Bankart.
          </Typography>
          <Typography>
            Le choix du type d&apos;intervention est fait par le chirurgien en prenant en compte
            plusieurs facteurs : l&apos;âge du patient, le type de sport
            pratiqué (contact, armé contré),
            le niveau de sport, présence d&apos;une hyperlaxité (rotation externe de plus de 90°)
            et présence de lésions osseuses au niveau de l&apos;humérus et de la glène.
          </Typography>
          <Typography>
            L&apos;opération est réalisée sous anesthésie générale. Une anesthésie locorégionale
            (bloc inter-scalénique) de complément permet de limiter les douleurs post-opératoires.
            L&apos;hospitalisation est de courte durée
            (1 nuit après l&apos;opération ou en ambulatoire).
          </Typography>

          <div className="flex flex-col space-y-2">
            <Typography size="mdx" weight="bold">1. Butée de Latarjet :</Typography>
            <Separator width="w-[100px]" />
          </div>
          <Typography>
            La butée de Latarjet est principalement indiquée en cas de luxations récidivantes
            avec des lésions osseuses (encoche de la tête humérale ou fracture de la glène)
            ou chez patients hyperlaxes.
          </Typography>
          <Typography>
            L&apos;intervention est réalisée à l&apos;aide d&apos;une petite
            incision de 5cm au niveau de
            la face antérieure de l&apos;épaule, permettant de prélever la coracoïde et le
            tendon conjoint (tendon du muscle coraco-brachial et du biceps) qui s&apos;y insère.
            La coracoïde est ensuite fixée sur la glène à l&apos;aide de deux vis (Figure 10).
          </Typography>
          <FigureTriple
            height="h-[300px]"
            imageNameOne="figure10-instabiliteanterieuredelepaule.jpg"
            imageNameTwo="figure10bis-instabiliteanterieuredelepaule.jpg"
            imageNameThree="figure10bis2-instabiliteanterieuredelepaule.jpg"
            caption="Figure 10. Stabilisation chirurgicale de l'épaule par une butée osseuse selon Latarjet, prélevée sur la coracoïde"
            currentOpTitle="Instabilité antérieure de l'épaule"
          />
          <Typography>
            On observe ainsi la stabilisation de l&apos;épaule
            par un double effet :

          </Typography>
          <List>
            <List.Item>
              <Typography>
                L&apos;effet « butée » de la coracoïde par augmentation du
                diamètre antéro-postérieur de la glène.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                L&apos;effet hamac du tendon conjoint qui se positionne
                en avant de la tête de l&apos;humérus lors du mouvement
                luxant de l&apos;épaule (l&apos;armée du bras).
              </Typography>
            </List.Item>
          </List>
          <Typography>
            Un drain est mis en place dans l&apos;épaule en fin d&apos;intervention afin
            de limiter la survenue d&apos;hématome. Il sera retiré le jour de la sortie du patient.
          </Typography>
          <Typography>
            L&apos;épaule est immobilisée dans un gilet coude au corps pendant 21 jours.
            Durant cette période,
            le patient pourra retirer son gilet pour les gestes de la vie quotidienne
            tout en évitant la rotation externe.
          </Typography>
          <Typography>
            La rééducation est initiée à J21, après le rendez-vous de contrôle
            clinique et radiologique.
            Un scanner est ensuite réalisé au 3ème mois post-opératoire afin d&apos;analyser le
            positionnement et la consolidation de la butée (Figure 11). La reprise du sport
            est autorisée au 4ème mois post-opératoire.
          </Typography>
          <Figure maxWidth="max-w-[400px]" imageName="figure11-instabiliteanterieuredelepaule.jpg" caption="Figure 11. Scanner post-opératoire à 3 mois: butée en bonne position, fusionnée avec la glène" currentOpTitle="Instabilité antérieure de l'épaule" />
          <div className="flex flex-col space-y-2">
            <Typography size="mdx" weight="bold">2. Intervention de Bankart :</Typography>
            <Separator width="w-[100px]" />
          </div>
          <Typography>
            L&apos;intervention de Bankart est indiquée en cas de subluxations, d&apos;épaule
            douloureuse instable ou en cas de luxations récidivantes sans lésion osseuse.
          </Typography>
          <Typography>
            Le principe de cette intervention est de retendre la capsule, le bourrelet
            et ligaments de l&apos;épaule à l&apos;aide de trois ancres qui sont impactées
            dans la glène (Figure 12).
          </Typography>
          <FigureDouble
            maxWidth="max-w-[400px]"
            height="h-[200px]"
            imageNameOne="figure12-instabiliteanterieuredelepaule.jpg"
            imageNameTwo="figure12bis-instabiliteanterieuredelepaule.jpg"
            caption="Figure 12. Réinsertion du bourrelet glénoïdien sous arthroscopie par trois ancres"
            currentOpTitle="Instabilité antérieure de l'épaule"
          />
          <Typography>
            Cette intervention est réalisée sous arthroscopie afin de garantir une chirurgie moins
            invasive, moins douloureuse avec une récupération post-opératoire
            plus rapide. Deux petite
            incisons de moins d&apos;un centimètre sont
            réalisées : une en arrière pour y mettre l&apos;optique
            et la caméra et une en avant pour y introduire les instruments.
          </Typography>
          <Typography>
            Dans les suites de l&apos;opération, l&apos;épaule est immobilisée
            à l&apos;aide d&apos;un gilet coude au
            corps pour une durée de 4 à 6 semaines. La rééducation est débutée
            dès l&apos;ablation du gilet.
            La récupération des amplitudes et de la force se fait de manière
            progressive sur 3 à 6 mois.
            La reprise du sport est en général autorisée à partir du 4ème mois post-opératoire.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour une instabilité antérieure de l'épaule ?">
          <Typography>
            En cas de réalisation de l&apos;opération par un chirurgien spécialisé du membre
            supérieur, les risques de complication sont rares. Il faut néanmoins
            prendre en compte les complications suivantes :
          </Typography>
          <List>
            <List.Item><Typography weight="bold">Complications liées à l&apos;anesthésie.</Typography></List.Item>
            <List.Item>
              <Typography weight="bold">
                Infection
                <Typography variant="span">: rare en raison de l&apos;application des règles d&apos;hygiène et de l&apos;administration d&apos;une antibioprophylaxie pendant l&apos;opération. Le germe le plus souvent incriminé est le P.Acnès.</Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Hématome
                <Typography variant="span">
                  {' '}
                  : exceptionnel en cas d&apos;intervention de Bankart. En cas de
                  butée de Latarjet, la mise en place d&apos;un drain réduit
                  considérablement ce risque.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Récidive d&apos;instabilité
                <Typography variant="span">
                  {' '}
                  :
                  très rare en cas de butée de Latarjet (5% des cas).
                  En cas d&apos;intervention de Bankart,
                  le risque de récidive est plus élevé (jusqu&apos;à 35%
                  des cas selon les données de la
                  littérature) et est principalement lié à une mauvaise sélection du patient.
                  En effet, l&apos;intervention de Bankart n&apos;est pas conseillée en cas
                  de lésions osseuse ou en cas d&apos;hyperlaxité.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                La défaillance matérielle
                <Typography variant="span">
                  {' '}
                  :
                  rare, peut être observée en cas d&apos;intervention de Bankart
                  (arrachage de l&apos;ancre et des fils de suture) ou de butée
                  de Latarjet (fracture ou torsion des vis).
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Résorption de la butée
                <Typography variant="span">
                  {' '}
                  :
                  on peut observer une résorption de la partie supérieure de la
                  butée lors de la première année post-opératoire. Il s&apos;agit d&apos;une
                  situation sans gravité, la stabilité de l&apos;épaule étant assurée
                  par la partie basse de la butée et le tendon conjoint.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Gêne par le matériel
                <Typography variant="span">
                  {' '}
                  :
                  les vis de butée de Latarjet peuvent être responsables
                  d&apos;une douleur antérieure
                  de l&apos;épaule. Cette situation peut être favorisée
                  par la résorption de la partie
                  supérieure de la butée. En cas de gêne persistante, une ablation
                  des vis peut être envisagée.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Douleur et raideur de l&apos;épaule
                <Typography variant="span">
                  {' '}
                  :
                  habituelle dans les suites post-opératoire. La récupération est le
                  plus souvent favorable avec les séances de rééducation. Une limitation
                  de la rotation externe peut être observée en cas de butée de Latarjet.
                </Typography>
              </Typography>
            </List.Item>
            <List.Item>
              <Typography weight="bold">
                Capsulite rétractile
                <Typography variant="span">
                  {' '}
                  (ou algodystrophie) :
                  se traduisant par des douleurs et un enraidissement de l&apos;épaule pouvant
                  atteindre tout le membre supérieur. Il s&apos;agit d&apos;une complication
                  non prévisible, pouvant durer 12 à 18 mois.
                </Typography>
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="epaule" />
  </Layout>
);

export default InstabiliteAnterieureDeLepaule;
